<template>
  <div id="posterShare">
    <el-dialog
      v-if="dialogVisible"
      :show-close="false"
      :visible="dialogVisible"
      class="dialogvisible"
      width="343px"
      @update:visible="(v) => $emit('update:dialogVisible', v)"
      :destroy-on-close="true"
      @close="cancel"
    >
      <div style="position: relative">
        <div id="poster11">
          <img src="~@ass/img/1.4.1.3/img_hbbg@2x.png" alt="" />
          <div class="contain">
            <div class="code">
              {{ type == 1 ? '兑换码' : ' 提取码' }}：{{
                postval.code || postval.exchange_code
              }}
            </div>
            <div class="time">
              有效期 ：
              <span v-if="postval.redeem_code_id">
                <!-- 兑换码 -->
                {{ postval.validity | formatTimeStamp('YYYY-MM-dd hh:mm') }}
              </span>
              <span v-else>
                {{ postval.validity | formatTimeStamp('YYYY-MM-dd') }}
              </span>
            </div>
            <div class="codetu" id="qrcode"></div>
            <div class="tip">长按识别二维码使用</div>
          </div>
          <div class="close" @click="cancel"></div>
        </div>
        <div class="result-pic" v-if="resultPic">
          <img :src="resultPic" />
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import html2canvas from 'html2canvas'
import QRCode from '@/assets/js/qrcode'
export default {
  name: 'posterShare',
  data() {
    return {
      resultPic: '',
    }
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    postval: {
      type: Object,
      default: () => {},
    },
    //1是兑换码详情，2是提取码
    type: {
      type: Number,
      default: 1,
    },
  },
  methods: {
    //生成海报
    create() {
      window.scrollTo(0, 0)
      const poster11 = document.getElementById('poster11')
      if (poster11) {
        html2canvas(poster11, {
          useCORS: true,
          logging: false,
        })
          .then((canvas) => {
            this.resultPic = canvas.toDataURL('image/png')
            this.complete_erweima = false
          })
          .catch((res) => {
            console.log(res)
          })
      }
    },
    // 生成二维码
    create_ewm() {
      const size = 130
      document.getElementById('qrcode').innerHTML = ''
      this.resultPic = ''
      const node = document.getElementById('qrcode')
      if (node) {
        this.qrcode = new QRCode(node, {
          text: this.postval.url, //地址
          width: size,
          height: size,
          colorDark: '#000000',
          colorLight: '#ffffff',
          correctLevel: QRCode.CorrectLevel.H,
        })
      }
      setTimeout(() => {
        this.create()
      }, 1000)
    },
    cancel() {
      // 取消后回复选择数据原样
      this.$emit('update:dialogVisible', false)
    },
  },
}
</script>

<style lang="less" scoped>
.dialogvisible {
  ::v-deep .el-dialog__header {
    padding: 0;
  }
  ::v-deep .el-dialog__body {
    padding: 0;
  }
  img {
    height: 100%;
    width: 100%;
    position: relative;
  }
  .contain {
    position: absolute;
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -44%);
    .code {
      font-size: 24px;
      font-weight: bold;
      color: #f4553c;
      line-height: 24px;
      white-space: nowrap;
    }
    .time {
      margin: 18px 0 111px;
      font-size: 13px;
      font-weight: 500;
      color: #f15b3c;
      line-height: 12px;
    }
    .codetu {
      width: 130px;
      height: 130px;
      margin: 0 auto;
    }
    .tip {
      margin-top: 14px;
      font-size: 14px;
      color: #0aa29b;
      line-height: 19px;
    }
  }
  .close {
    cursor: pointer;
    position: absolute;
    right: -50px;
    top: 0;
    height: 30px;
    width: 30px;
    background: url('~@ass/img/1.4.1.3/icon_gban@2x.png') no-repeat;
    background-size: 29px;
  }
  .result-pic {
    width: 343px;
    height: 466px;
    position: absolute;
    top: 0px;
    left: 0px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>